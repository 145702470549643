import React from 'react';
import Link from '../../utils/Link';
import Image from '../../utils/OptimizedImage';
import { Container } from './styles/SocialLink.styled';

const SocialLink = ({ component }) => {
  const { url, icon } = component;
  if (!url || !icon) return null;
  return (
    <Container>
      <Link to={url}>
        {icon?.gatsbyImageData && (
          <Image
            image={icon?.gatsbyImageData}
            alt={icon?.alt}
            title={icon?.title}
          />
        )}
      </Link>
    </Container>
  );
};

export default SocialLink;
